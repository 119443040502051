<template>
  <header class="header">
    <div class="header__container">
      <div class="header__container__left">
        <div class="header__logo">
          <BaseIcon icon="logo/white" />
        </div>
      </div>

      <div class="header__container__right">
        <button class="button pricing">Pricing</button>
        <a class="button grey" href="https://dev.app.drawberry.io/login"
          >Login</a
        >
        <a class="button success" href="https://dev.app.drawberry.io/login"
          >Get Started</a
        >
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";
export default defineComponent({
  name: "HeaderComponent",
  components: { BaseIcon },
});
</script>

<style></style>
