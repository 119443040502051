<template>
  <section id="home">
    <div class="home">
      <HomeHeader />
      <HomeContent />
    </div>
    <div class="color-bar"></div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HomeHeader from "@/components/home/HomeHeader.vue";
import HomeContent from "@/components/home/HomeContent.vue";

export default defineComponent({
  name: "HomeView",
  components: { HomeContent, HomeHeader },
});
</script>
