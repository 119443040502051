<template>
  <iframe
    data-tally-src="https://tally.so/r/w5jPeP?transparentBackground=1"
    width="100%"
    height="100%"
    frameborder="0"
    marginheight="0"
    marginwidth="0"
    title="Elevate Your Business Communication with Drawberry Content Packs"
  ></iframe>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
export default defineComponent({
  name: "ContentPackView",
  setup() {
    const TYPEFORM_ID = "JPGOGtyR";
    const loadTypeform = async () => {
      return new Promise((resolve) => {
        const script = document.createElement("script");
        script.setAttribute("src", "//tally.so/widgets/embed.js");
        script.onload = resolve;
        document.head.appendChild(script);
      });
    };

    onMounted(async () => {
      await loadTypeform();
    });

    return {
      TYPEFORM_ID,
    };
  },
});
</script>

<style>
html {
  margin: 0;
  height: 100%;
  overflow: hidden;
}
iframe {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 0;
}
</style>
