<template>
  <header class="home__header">
    <div class="home__header__logo">
      <BaseIcon icon="logo/white" />
    </div>
    <div class="home__header__socials">
      <a
        target="_blank"
        href="https://instagram.com/drawberry.io?igshid=MzRlODBiNWFlZA=="
      >
        <BaseIcon icon="socials/instagram" />
      </a>
      <a
        target="_blank"
        href="https://x.com/drawberryio?s=21&t=pFn-bgXLT43EhXbYBcssrw"
      >
        <BaseIcon icon="socials/twitter" />
      </a>
      <a target="_blank" href="https://www.facebook.com/drawberryio">
        <BaseIcon icon="socials/facebook" />
      </a>
      <a target="_blank" href="https://www.linkedin.com/company/drawberry-io/">
        <BaseIcon icon="socials/linkedin" />
      </a>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";

export default defineComponent({
  name: "HomeHeader",
  components: { BaseIcon },
});
</script>

<style scoped></style>
