import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ContentPackView from "../views/ContentPackView.vue";
import PrivacyAndTerms from "../views/PrivacyAndTerms.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/:catchAll(.*)",
    component: HomeView,
  },
  {
    path: "/content-pack",
    name: "ContentPackView",
    component: ContentPackView,
  },
  {
    path: "/privacy-and-terms",
    name: "PrivacyAndTerms",
    component: PrivacyAndTerms,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
