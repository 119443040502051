<template>
  <form @submit.prevent="addToWaitlist" class="home__content__waitlist__form">
    <template v-if="!submitted">
      <input
        type="email"
        v-model="email"
        placeholder="Please enter your email address"
        required
      />
      <button :disabled="disabled" type="submit">
        <span> Join the waitlist </span>
        <BaseIcon icon="arrow/right" />
      </button>
    </template>
    <template v-else>
      <h5 class="home__content__waitlist__form__success">
        Thank you ✨. You’re now on the waitlist!
      </h5>
    </template>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseIcon from "@/components/icon/BaseIcon.vue";
import axios from "axios";

export default defineComponent({
  name: "WaitlistForm",
  components: { BaseIcon },

  setup() {
    const email = ref("");
    const submitted = ref(false);
    const disabled = ref(false);

    const validateEmail = (email: string) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };

    const addToWaitlist = async () => {
      try {
        if (!validateEmail(email.value)) return;

        disabled.value = true;

        const res = await axios.post(
          `${process.env.VUE_APP_BACKEND_URL}/join-waitlist`,
          {
            email: email.value,
          }
        );
        if (res.data.error === false) {
          submitted.value = true;
        }
      } catch (e) {
        // email.value
      }

      disabled.value = false;
    };

    return {
      email,
      addToWaitlist,
      disabled,
      submitted,
    };
  },
});
</script>

<style scoped></style>
