<template>
  <footer class="footer">
    <p>{{ new Date().getFullYear() }} © All rights reserved, Drawberry ®</p>
    <p>Made with 💙 by <strong>Digikolture</strong></p>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "FooterComponent",
});
</script>

<style></style>
