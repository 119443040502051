import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "data-tally-src": "https://tally.so/r/w5jPeP?transparentBackground=1",
  width: "100%",
  height: "100%",
  frameborder: "0",
  marginheight: "0",
  marginwidth: "0",
  title: "Elevate Your Business Communication with Drawberry Content Packs"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("iframe", _hoisted_1))
}