<template>
  <section class="home__content">
    <div class="home__content__titles">
      <h1>
        <span class="very">Very</span> <span class="berry">Berry</span> Good
        Emails will be <span class="here">here soon</span>
      </h1>
      <h6>
        Keep your audience engaged. Stand Out in a cluttered inbox with our easy
        to modify dynamic email components.
      </h6>
    </div>

    <div class="home__content__waitlist">
      <WaitlistForm />
      <p>
        By entering your email address, you agree to get notified when our App
        is launched.
      </p>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import WaitlistForm from "@/components/home/WaitlistForm.vue";

export default defineComponent({
  name: "HomeContent",
  components: { WaitlistForm },
});
</script>

<style scoped></style>
