<template>
  <section class="app">
    <section class="app__container">
      <Header />

      <div class="privacy-terms">
        <div class="privacy-terms__container">
          <h1>Effective date: [datePubished]</h1>

          <div class="privacy-terms___items">
            <section
              v-for="(item, key) in data"
              :key="key"
              :id="item.id"
              class="privacy-terms___item"
            >
              <div class="privacy-terms___item-titles">
                <h3>{{ item.title }}</h3>
                <p>{{ item.description }}</p>
              </div>

              <div class="privacy-terms___item-content">
                <p>{{ item.list_description }}</p>
                <section class="privacy-terms__list__numbers">
                  <div
                    v-for="(item2, key) in item.list"
                    :key="key"
                    class="privacy-terms__list__item"
                  >
                    <p>
                      <span>{{ key + 1 }}.</span>
                      <span v-html="item2.description"></span>
                    </p>
                    <div
                      v-if="item2.items"
                      class="privacy-terms__list__item__list"
                    >
                      <p v-for="(item3, key) in item2.items" :key="key">
                        <span> • </span> {{ item3 }}
                      </p>
                    </div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
      </div>

      <Footer />
    </section>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";

export default defineComponent({
  name: "PrivacyAndTerms",
  components: { Footer, Header },

  setup() {
    const data = [
      {
        id: "terms",
        title: "Terms of use",
        description: "Welcome to Drawberry, a SaaS product",
        list_description:
          "By accessing or using Drawberry, you agree to be bound by these Terms of Use. If you do not agree with any part of these terms, you may not use our services.",
        list: [
          {
            description:
              "Acceptance of Terms: These Terms of Use constitute a legally binding agreement between you and Drawberry. By accessing or using our services, you agree to comply with these terms.",
          },
          {
            description:
              "Description of Service: Drawberry is a drag and drop email builder that enables users to create visually appealing emails quickly and easily through a web browser.",
          },
          {
            description:
              "User Accounts: You may be required to create an account to access certain features of Drawberry. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.",
          },
          {
            description:
              "Intellectual Property: All content and materials available on Drawberry, including but not limited to text, graphics, logos, images, and software, are the property of Digikolture Limited or its licensors and are protected by intellectual property laws.",
          },
          {
            description:
              "Prohibited Activities: You agree not to engage in any of the following prohibited activities:",
            items: [
              "Violating any applicable laws or regulations",
              "Interfering with the security or integrity of Drawberry",
              "Reverse engineering or attempting to access the source code of Drawberry",
              "Using Drawberry for any illegal or unauthorized purpose",
            ],
          },
          {
            description:
              'Disclaimer of Warranties: Drawberry is provided on an "as is" and "as available" basis without any warranties of any kind, express or implied. Digikolture Limited makes no warranties regarding the accuracy, reliability, or availability of Drawberry.',
          },
          {
            description:
              "Limitation of Liability: In no event shall Digikolture Limited be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of Drawberry.",
          },
          {
            description:
              "Modification of Terms: Digikolture Limited reserves the right to modify or revise these Terms of Use at any time without prior notice. Your continued use of Drawberry following any such changes constitutes your acceptance of the revised terms.",
          },
          {
            description:
              "Governing Law: These Terms of Use shall be governed by and construed in accordance with the laws of [Federal Republic of Nigeria], without regard to its conflict of law principles.",
          },
          {
            description:
              "Contact Information: If you have any questions or concerns about these Terms of Use, you may contact us at  <strong><a href='mailto:hello@drawberry.io'>hello@drawberry.io</a> </strong>.",
          },
        ],
      },
      {
        id: "privacy",
        title: "Privacy policy",
        description:
          "At Drawberry, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose your personal information when you use our services.",
        list_description: "",
        list: [
          {
            description: "Information We Collect:",
            items: [
              "Account Information: When you create an account on Drawberry, we may collect information such as your name, email address, and password.",
              "Usage Information: We may collect information about how you use Drawberry, including your interactions with the platform and the emails you create.",
              "Device Information: We may collect information about the device you use to access Drawberry, such as your IP address, browser type, and operating system.",
            ],
          },
          {
            description: "Use of Information:",
            items: [
              "Provide and Improve Services: We use the information we collect to provide and improve Drawberry, including customizing your experience and developing new features.",
              "Communications: We may use your email address to send you updates, newsletters, or other communications related to Drawberry.",
              "Analytics: We may use analytics tools to analyse usage trends and patterns on Drawberry.",
            ],
          },
          {
            description: "Sharing of Information:",
            items: [
              "Service Providers: We may share your information with third-party service providers who assist us in operating Drawberry.",
              "Legal Compliance: We may disclose your information if required by law or if we believe that such action is necessary to comply with a legal obligation.",
            ],
          },
          {
            description:
              "Security: We take reasonable measures to protect the security of your personal information and to prevent <strong>unauthorised</strong> access, disclosure, or alteration.",
          },
          {
            description:
              "Retention of Information: We will retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.",
          },
          {
            description:
              "Your Choices: You may choose not to provide certain personal information, but this may limit your ability to use certain features of Drawberry.",
          },

          {
            description:
              "Children's Privacy: Drawberry is not intended for children under the age of 13, and we do not knowingly collect personal information from children under this age.",
          },
          {
            description:
              "Changes to this Policy: We may update this Privacy Policy from time to time, and any changes will be effective upon posting the revised policy on Drawberry.",
          },
          {
            description:
              "Contact Us: If you have any questions or concerns about this Privacy Policy, please contact us at <strong><a href='mailto:hello@drawberry.io'>hello@drawberry.io</a> </strong>.",
          },
        ],
      },
    ];

    return { data };
  },
});
</script>
