import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "home__header" }
const _hoisted_2 = { class: "home__header__logo" }
const _hoisted_3 = { class: "home__header__socials" }
const _hoisted_4 = {
  target: "_blank",
  href: "https://instagram.com/drawberry.io?igshid=MzRlODBiNWFlZA=="
}
const _hoisted_5 = {
  target: "_blank",
  href: "https://x.com/drawberryio?s=21&t=pFn-bgXLT43EhXbYBcssrw"
}
const _hoisted_6 = {
  target: "_blank",
  href: "https://www.facebook.com/drawberryio"
}
const _hoisted_7 = {
  target: "_blank",
  href: "https://www.linkedin.com/company/drawberry-io/"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BaseIcon, { icon: "logo/white" })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", _hoisted_4, [
        _createVNode(_component_BaseIcon, { icon: "socials/instagram" })
      ]),
      _createElementVNode("a", _hoisted_5, [
        _createVNode(_component_BaseIcon, { icon: "socials/twitter" })
      ]),
      _createElementVNode("a", _hoisted_6, [
        _createVNode(_component_BaseIcon, { icon: "socials/facebook" })
      ]),
      _createElementVNode("a", _hoisted_7, [
        _createVNode(_component_BaseIcon, { icon: "socials/linkedin" })
      ])
    ])
  ]))
}